<template>
<v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="400px">    
      <v-card>
        <v-card-title>{{$t('keydetail.details')}}</v-card-title>
        <v-divider></v-divider>
        <!-- {{filedata}} -->
        <v-card-text>
          <v-list two-line subheader>
      <v-subheader>{{$t('keydetail.general')}} <v-divider class="mx-4" ></v-divider></v-subheader>
        <v-list-item-icon style="padding-left: 4%">
         <v-icon x-large :color="seticon(filedata.data_type)[1]" style="font-weight: 100">{{seticon(filedata.data_type)[0]}}</v-icon>
         </v-list-item-icon>
         <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('keydetail.filename')}}</v-list-item-title>
          <v-list-item-subtitle>{{filedata.filename}}</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>           
          <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('keydetail.document_no')}}</v-list-item-title>
          <v-list-item-subtitle>{{filedata.document_no}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('keydetail.document_type')}}</v-list-item-title>
          <v-list-item-subtitle>{{filedata.document_type}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
          <v-list-item-content>
           <v-list-item-title>Relation</v-list-item-title>
          <v-list-item-subtitle>{{filedata.relation}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('keydetail.filetype')}}</v-list-item-title>
          <v-list-item-subtitle>{{filedata.data_type}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content >
          <v-list-item-title>{{$t('keydetail.receiver_name')}}</v-list-item-title>
          <v-list-item-subtitle>{{filedata.receiver_name}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content >         
          <v-list-item-title>{{$t('keydetail.sender_name')}}</v-list-item-title>
          <v-list-item-subtitle>{{filedata.sender_name}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content >         
          <v-list-item-title>{{$t('keydetail.cre_dtm')}}</v-list-item-title>
          <v-list-item-subtitle>{{formatdatetime(filedata.cre_dtm)}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </v-list>
         
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('closedialog')" color="red" dark>{{$t('keydetail.closeDialog')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</template>

<script>
export default {
    props: ["show","filedata"],
    data: function() {
    return{
    }
    },
    methods:{
    formatdatetime(_datetime) {
      if(_datetime === "" || _datetime === null || _datetime === undefined){
        return "-";
      }else{ 
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
        setformatdate(_date) {
      let data =
        _date.substring(8, 6) +
        "/" +
        _date.substring(6, 4) +
        "/" +
        _date.substring(0, 4);
      return data || "-";
    },
   seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    },
    computed:{
        shower :{
            get() {
                if(this.show === true){
                }
                return this.show
            }
        }
    }
}
</script>